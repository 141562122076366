import { initLogRocket } from '@/modules/logrocket/logrocket'
import { getBFFRoute, getHeaderFromResponse } from '../helpers/http'

import axios from 'axios'

export const getConfiguration = async () => {
  const path = `${getBFFRoute()}/api/config`
  const response = await axios.get(path)
  const csrf = getHeaderFromResponse('x-csrf-token', response)
  const version = '2110'

  if (response?.data?.environment) {
    initLogRocket(response.data.environment, version)
  }

  return { config: { ...response.data, version }, csrf }
}
